import main from './main';

export async function apiCodeToOpenId(code: string, appId: string) {
  let res = await main.api.get(`/client/common/getUserOpenId?code=${code}&appId=${appId}`);
  return res;
}

/// 获取 验证码 通用
export async function apiGetCommonCaptcha(tel: string) {
  let res = await main.api.get('/resource/sysSms/code?phonenumber=' + tel + '&applyDetailName=' + main.appName);
  return res;
}

/// 登录 得到token
export async function apiLogin(tel: string, captcha: string) {
  let res = await main.api.post('/auth/smsLogin', {
    phonenumber: tel,
    smsCode: captcha,
    deptId: main.deptId,
    appType: main.appName,
    appName: main.appName,
  });
  return res;
}

/// 获取用户信息
export async function apiGetUserInfo(token: string) {
  main.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  let res = await main.api.get('/system/user/getInfo');
  if (!main.openId) {
    getOpenId();
  }
  return res;
}

async function getOpenId() {
  if (main.code) {
    try {
      let res = await apiCodeToOpenId(main.code, main.appId);
      let data = res.data;
      if (data.code === 200) {
        main.openId = data.data;
        localStorage.setItem(main.appId, main.openId ?? '');
      }
    } catch (e) {}
  }
}

// 获取用户设备数量
export async function apiGetEmCount() {
  return await main.api.get('/client/cardEquipment/emCount');
}

// 获取当前卡
export async function apiGeEmInfo() {
  var res = await main.api.get('/client/cardEquipment/getInfo');
  return res;
}
// 获取设备列表
export async function apiGetEmList() {
  return await main.api.get('/client/cardEquipment/list');
}

/// 绑定设备
export async function apiBindEm(emNumber: string, remark = '') {
  var res = await main.api.post('/client/cardEquipment/bind', {
    emNumber,
    remark: remark,
    defaultStatus: true,
  });

  return res;
}

/// 解除绑定设备
export async function apiRemoveEm(emId: string) {
  var res = await main.api.post(`/client/cardEquipment/unbind?emId=${emId}`);

  return res;
}

/// 编辑卡信息
export async function apiEditEm(id: string, remark?: string, isDefault?: boolean) {
  var res = await main.api.post('/client/cardEquipment/setDefault', {
    id: id,
    remark: remark,
    defaultStatus: isDefault,
  });
  return res;
}

/// 获取套餐包列表
export async function apiGetEmPackList(emId: string) {
  var res = await main.api.get('/client/cardPackDetail/listByEmId', {
    params: {
      emId,
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/// 新增和修改操作密码
export async function apiCreateAndEditPwd(pwd?: string, captcha?: string, phone?: string) {
  var res = await main.api.post('/client/cardUser/editPayPassWordByPhoneNumber', {
    phoneNumber: phone,
    code: captcha,
    payPassWord: pwd,
  });

  return res;
}

/// 续充活动列表
export async function apiGetSecondActivityList(emId: string, cardPackDetailId?: string) {
  var res = await main.api.get('/client/cardPay/renewal', { params: { emId, cardPackDetailId } });

  return res;
}

/// 直充活动列表
export async function apiGetDirectRechargeList(emId: string) {
  var res = await main.api.get('/client/cardPay/directList', { params: { emId } });

  return res;
}

/// 获取用户余额和是否有密码
export async function apiGetBalance(emId: string) {
  var res = await main.api.get('/client/cardDetailWallet', { params: { emId } });

  return res;
}

/// 获取可用优惠券列表
export async function apiGetAvailableCouponList(emId: string, cardPackDetailId: string, number: number) {
  var res = await main.api.get('/client/cardPackOrder/getCouponListByCardPackDetailId', {
    params: {
      emId,
      cardPackDetailId: cardPackDetailId,
      number,
    },
  });
  return res;
}

/// 支付优惠
export async function apiGetPayConfigReduction(emId: string) {
  var res = await main.api.get('/client/payConfig/discounts', { params: { emId } });
  return res;
}

// 获取支付类型列表
export async function apiGetPayTypeConfigList() {
  var res = await main.api.get('/client/payConfig/list', {
    params: {
      status: 'enable',
      orderByColumn: 'sortNum',
      isAsc: 'asc',
      appName: main.appName,
    },
  });
  return res;
}

/// 订单预览
export async function apiPreOrder(
  cardPackDetailId: string,
  emId: string,
  couponCodeIds?: Array<string>,
  dateType?: string,
  number?: number
) {
  var res = await main.api.post('/client/cardPackOrder/preview', {
    cardPackDetailId: cardPackDetailId,
    emId,
    couponCodeIds: couponCodeIds,
    dateType: dateType,
    number,
  });
  return res;
}

/// 统一支付
export async function apiUnifiedPay(body: any) {
  var res = await main.api.post('/client/cardDetailWalletOrder/unifiedPay', body);
  return res;
}
/// 获取设备下卡列表
export async function apiGetCardList(emId: string) {
  var res = await main.api.get('/client/cardDetail/list', { params: { emId } });
  return res;
}

// 首页统计 实名
export async function apiGetRealCount(emId: string) {
  var res = await main.api.get('/client/cardDetail/realCount', { params: { emId } });
  return res;
}
// 首页统计 套餐
export async function apiGetOrderCount(emId: string) {
  var res = await main.api.get('/client/cardPackOrderDetail/orderCount', { params: { emId } });
  return res;
}
// 首页统计 优惠券
export async function apiGetCouponCount(emId: string) {
  var res = await main.api.get('/client/payCouponCardUser/getCount', { params: { emId } });
  return res;
}
// 首页统计 充销记录
export async function apiGetIncomeCount(emId: string) {
  var res = await main.api.get('/client/cardDetailWalletOrder/walletCount', { params: { emId } });
  return res;
}

/// 获取套餐订购记录列表
export async function apiGetPackBuyLogList(emId: string) {
  var res = await main.api.get('/client/cardPackOrderDetail/list', {
    params: {
      emId,
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/// 获取充消记录
export async function apiGetIncomeList(emId: string) {
  var res = await main.api.get('/client/cardDetailWalletLog/list', {
    params: {
      emId,
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/// 创建余额充值订单
export async function apiBalanceRecharge(emId: string, cardPayDetailId: string, appId: string, openId: string) {
  var res = await main.api.post('/client/cardDetailWalletOrder/create', {
    emId,
    cardPayDetailId,
    appId,
    openId,
  });

  return res;
}
/// 获取余额充值订单支付参数
export async function apiGetBalanceRechargePayInfo(orderId: string, payType: string, openId: string) {
  var res = await main.api.post('/client/cardDetailWalletOrder/getPayInfo', {
    orderId: orderId,
    payType: payType,
    openId: openId,
    appId: main.appId,
    // 'payConfigId': payTypeId,
  });

  return res;
}

/// 获取移动实名链接
export async function apiGetRealUrl(cardId: string) {
  var res = await main.api.get('/client/common/getRealUrl', { params: { cardId: cardId } });
  return res;
}
/// 延迟实名认证
export async function apiUpdateRealTime(cardId: string) {
  var res = await main.api.post('/client/cardDetail/updateRealTime', { cardId: cardId });

  return res;
}
// jsapi配置
export async function apiGetWeChatSignature(url: string, appId: string) {
  return await main.api.get(`/client/common/getWeChatSignature`, { params: { url: encodeURIComponent(url), appId } });
}
/// 退出登录
export async function apiLogout() {
  var res = await main.api.delete('/auth/logout');
  return res;
}

// 获取配置列表
export async function apiGetConfigList() {
  return await main.api.post('/client/common/getConfig');
}
// 根据类型获取协议
export async function apiGetProtocol(type: string) {
  let res = await main.api.get('/client/sysPrivacy/list', {
    params: {
      pageSize: 1,
      pageNum: 1,
      orderByColumn: 'createTime',
      isAsc: 'desc',
      textType: type,
      appName: main.appName ?? 'ctFlow',
    },
  });

  return res;
}

// 获取登录页的客服链接
export async function apiGetLoginCustomerUrl() {
  var res = await main.api.get('/client/cardServiceGroup/login', { params: { appName: main.appName } });
  return res;
}

// 设备检测
export async function apiInspect(emId: string) {
  var res = await main.api.get('/client/cardEquipment/inspect', { params: { emId } });
  return res;
}

/// 获取优惠券列表
export async function apiGetCouponList(emId: string) {
  var res = await main.api.get('/client/payCouponCardUser/list', {
    params: {
      pageNum: 1,
      pageSize: 1000,
      emId,
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/// 获取优惠券类型数量统计
export async function apiGetCouponTypeCount(emId: string) {
  return await main.api.get('/client/payCouponCardUser/getTypeCount', { params: { emId } });
}
/// 使用优惠券
export async function apiUseFlowCoupon(id: string) {
  var res = await main.api.post(`/client/payCouponCardUser/useFlow?id=${id}`);
  return res;
}

/// 提交投诉建议
export async function apiCreateComplaint(data: Object) {
  let res = await main.api.post('/client/cardUserWorkOrder', data);

  return res;
}

/// 获取工单列表
export async function apiGetComplaintList() {
  let res = await main.api.get('/client/cardUserWorkOrder/list', {
    params: { orderByColumn: 'createTime', isAsc: 'desc' },
  });

  return res;
}

/// 获取工单详情
export async function apiGetComplaintDetail(id: string) {
  let res = await main.api.get('/client/cardUserWorkOrder/' + id);

  return res;
}

/// 图片上传
export async function apiUploadPic(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  let res = await main.api.post('/client/common/upload', formData);

  let url: string | null = null;
  if (res.data.code === 200) {
    url = res.data.data.url;
  }

  return url;
}

/// 切换网络
export async function apiSwitchingNetwork(data: any) {
  var res = await main.api.post('/client/cardDetail/switchingNetwork', data);
  return res;
}

/// 首页是否弹出活动
export async function apiIsActive(cardId: string, rechargeType: string) {
  var res = await main.api.get('/client/cardPay/isActive', { params: { emId: cardId, payType: rechargeType } });
  return res;
}

/// 首充活动列表
export async function apiGetFirstActivityList(cardId: string) {
  var res = await main.api.get('/client/cardPay/first', { params: { emId: cardId } });

  return res;
}
/// 购买首充活动 创建订单
export async function apiCreateFirstActivityOrder(cardId: string, cardPayDetailId: string) {
  var res = await main.api.post('/client/cardDetailWalletOrder/create', {
    emId: cardId,
    cardPayDetailId: cardPayDetailId,
  });
  return res;
}
/// 购买首充活动支付信息
export async function apiPayFirstActivityOrder(orderId: string, payType: string, openId: string, appId: string) {
  var res = await main.api.post('/client/cardDetailWalletOrder/getPayInfo', {
    orderId: orderId,
    payType: payType,
    openId,
    appId,
    // 'payConfigId': payTypeId,
  });
  return res;
}
