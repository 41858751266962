import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  apiGeEmInfo,
  apiGetConfigList,
  apiGetCouponCount,
  apiGetFirstActivityList,
  apiGetIncomeCount,
  apiGetOrderCount,
  apiGetRealCount,
  apiInspect,
  apiIsActive,
} from '../../api';
import Button from '../../components/button';
import Page from '../../components/page';
import main, { AppLocalKey } from '../../main';
import { OperatorType } from '../../utils/dict';
import {
  LoadingMask,
  copyToClipboard,
  flowKTOG,
  formatNumber,
  showBottom,
  showConfirm,
  showToast,
} from '../../utils/utils';
import FavoriteDialog from './favoriteDialog';
import { RechargeType } from '../../utils/dict';
import FirstActiveFloat from './firstActiveFloat';

export default function HomePage() {
  const navigate = useNavigate();
  const [currentEm, setCurrentEm] = useState<CurrentEm | undefined>(main.currentEm);
  const [realCount, setRealCount] = useState<{
    realNameY?: number;
    realNameN?: number;
    all?: boolean;
    description?: string;
  }>({});
  const [orderCount, setOrderCount] = useState(0);
  const [incomeCount, setIncomeCount] = useState(0);
  const [couponCount, setCouponCount] = useState(0);
  // 是否有首充活动 有的话就悬浮按钮 并判断是否直接跳转进去
  const [hasChargeFirstActive, setHasChargeFirstActive] = useState(false);
  // 是否强制活动
  const [forceActivity, setForceActivity] = useState(false);
  // 所有接口加载完，一般是在设备详情加载完之后，再加载各种统计和活动，最后一个接口设置加载完成
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    loadCurrentEM();
    loadConfig();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (loaded) {
      handleCheckPageStatus();
    }
  }, [loaded]); // eslint-disable-line

  async function handleCheckPageStatus() {
    // 是否自动跳到了别的页面，是的话不检测收藏弹窗，没有跳的时候才检测
    let toSomePage = false;
    if (hasChargeFirstActive && !main.toActivity[currentEm?.id!]) {
      if (!forceActivity) {
        main.toActivity[currentEm?.id!] = true;
      }
      toSomePage = true;
      navigate('/activity');
    } else if (
      (!main.currentEm?.packDetailId || !main.currentEm?.endEffectTime) &&
      !main.toBuyPackage[main.currentEm?.id!]
    ) {
      main.toBuyPackage[main.currentEm?.id!] = true;
      let modalRes = await showConfirm({
        title: '智能检测结果',
        content: '未购买套餐，无法提供联网服务',
        hideCancelBtn: true,
        confirmBtnText: '去订购套餐',
      });
      if (modalRes) {
        toSomePage = true;
        // 没有套餐
        navigate('/select-pack');
      }
    } else if (
      !realCount.all &&
      !realCount.realNameY &&
      !main.toRealName[main.currentEm?.id!] &&
      !main.toBuyPackage[main.currentEm?.id!]
    ) {
      main.toRealName[main.currentEm?.id!] = true;
      let modalRes = await showConfirm({
        title: '智能检测结果',
        content: '设备下有未实名认证卡片，无法提供联网服务',
        hideCancelBtn: true,
        confirmBtnText: '去实名认证',
      });
      if (modalRes) {
        toSomePage = true;
        navigate('/real-name');
      }
    }

    // 如果不需要跳页面 又没有弹出过收藏弹窗
    if (!toSomePage) {
      let isOpenDialog = localStorage.getItem(AppLocalKey.showFavoriteDialog);
      if (isOpenDialog !== 'true') {
        localStorage.setItem(AppLocalKey.showFavoriteDialog, 'true');
        await showBottom((onOk) => <FavoriteDialog onOk={onOk} />);
      }
    }
  }

  // 检查是否要弹活动
  async function checkFirstActivity(id: string) {
    try {
      let res = await apiIsActive(id, RechargeType.first);
      setHasChargeFirstActive(!!res.data.data);
    } catch (e) {}
    setLoaded(true);
  }

  async function loadActivity(id: string) {
    try {
      let res = await apiGetFirstActivityList(id);
      let data = res.data;
      if (data.code === 200) {
        let _data = data.data;
        setForceActivity(_data.forceActivity === 'Y');
      } else {
        showToast(data.msg ?? '获取活动失败,请重试');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取活动失败,请重试');
    }
  }

  async function loadConfig() {
    try {
      let configRes = await apiGetConfigList();
      let configData = configRes.data;
      if (configData.code === 200) {
        main.configList = configData.data;
      }
    } catch (e: any) {
      console.log(e);
    }
  }
  async function loadCurrentEM() {
    try {
      let res = await apiGeEmInfo();
      let data = res.data;

      if (data.data) {
        setCurrentEm(data.data);
        main.currentEm = data.data;
        await loadOrderCount(main.currentEm?.id!);
        await loadIncomeCount(main.currentEm?.id!);
        await loadCouponCount(main.currentEm?.id!);
        await loadRealCount(main.currentEm?.id!);
        await loadActivity(main.currentEm?.id!);
        await checkFirstActivity(main.currentEm?.id!);
      } else {
        // 没有卡
      }
    } catch (e: any) {
      // TODO: 网络错误
    }
  }

  async function loadRealCount(emId: string) {
    try {
      let res = await apiGetRealCount(emId);
      let data = res.data;
      if (data.code === 200) {
        setRealCount(data.data);
      }
    } catch (e: any) {}
  }

  async function loadIncomeCount(emId: string) {
    try {
      let res = await apiGetIncomeCount(emId);
      let data = res.data;
      if (data.code === 200) {
        setIncomeCount(data.data ?? 0);
      }
    } catch (e: any) {}
  }

  async function loadOrderCount(emId: string) {
    try {
      let res = await apiGetOrderCount(emId);
      let data = res.data;
      if (data.code === 200) {
        setOrderCount(data.data ?? 0);
      } else {
      }
    } catch (e) {}
  }

  async function loadCouponCount(emId: string) {
    try {
      let res = await apiGetCouponCount(emId);
      let data = res.data;
      if (data.code === 200) {
        setCouponCount(data.data ?? 0);
      } else {
      }
    } catch (e) {}
  }

  function handleCallPhone() {
    let tel400 = main.tel400;
    if (main.configList) {
      let config = main.configList.find((i) => i.configKey === main.tel400Key);
      if (config) {
        tel400 = config.configValue!;
      }
    }
    window.open(`tel:${tel400}`);
  }

  function handleToComplaint() {
    // handleCallPhone();
    navigate('/complaint-index');
  }
  // 智能检测
  async function handleInspect() {
    LoadingMask.show('正在检测...');
    try {
      let res = await apiInspect(currentEm?.id!);
      let data = res.data;
      LoadingMask.hide();
      if (data.code === 200) {
        let inspect: EmInspect = data.data;
        if (!!inspect.packOrder) {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content: '未购买套餐，无法提供联网服务',
            hideCancelBtn: true,
            confirmBtnText: '去订购套餐',
          });
          if (!modalRes) return;
          navigate('/select-pack');
        } else if (!!inspect.real) {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content: '设备下无实名认证卡片，无法提供联网服务',
            hideCancelBtn: true,
            confirmBtnText: '去实名认证',
          });
          if (!modalRes) return;
          navigate('/real-name');
        } else if (!!inspect.error) {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content:
              '当前卡片异常，建议切换联网卡或联系客服！异常原因如下：号卡存在异常使用情况，个人身份信息或号卡可能被不法份子利用！',
            cancelBtnText: '联系客服',
            confirmBtnText: '切换网络',
          });
          if (!modalRes) {
            navigate('/customer');
          } else {
            navigate('/switch-network');
          }
        } else if (!!inspect.flow) {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content: '当前套餐流量已用尽，可购买套餐后使用联网服务！',
            hideCancelBtn: true,
            confirmBtnText: '去订购套餐',
          });
          if (!modalRes) return;
          navigate('/select-pack');
        } else {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content: '设备正常，若依然无法联网，请联系客服！',
            cancelBtnText: '关闭',
            confirmBtnText: '联系客服',
          });
          if (!modalRes) return;
          navigate('/customer');
        }
      } else {
        showToast(data.msg ?? '检测失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '检测失败');
    } finally {
      LoadingMask.hide();
    }
  }

  // 复购 入口配置
  let reBuyEntery: Config | undefined;
  if (main.configList) {
    reBuyEntery = main.configList.find((i) => i.configKey === main.reBuyEnteryKey);
    if (!reBuyEntery?.configValue || !reBuyEntery?.remark) {
      // 没有设置图片或跳转连接就不显示
      reBuyEntery = undefined;
    }
  }

  // 实名情况 1 全实名 2 部分实名 3 未实名
  let realNameTag = '1';
  if (realCount.all) realNameTag = '1';
  else if (!!realCount.realNameY) realNameTag = '2';
  else realNameTag = '3';
  //--------------------------------------
  let surplusFlow = flowKTOG(currentEm?.surplusFlow);
  //------------------------------------------
  let realBlock = (
    <div
      className="d-flex-c w-155 h-90 p-10 pos-r bg-white border-radius-10 mt-10"
      onClick={async () => {
        if (!currentEm?.packDetailId || !currentEm?.endEffectTime) {
          let modalRes = await showConfirm({
            title: '提示',
            content: '请购买套餐后实名认证',
            cancelBtnText: '取消',
            confirmBtnText: '前往购买',
          });
          if (!modalRes) return;
          navigate('/select-pack');
          return;
        }
        navigate('/real-name');
      }}
    >
      <div className="text-color-9 flex-1">实名认证</div>
      <div className="font-size-10 text-color-9 mb-5">各个运营商下</div>
      {realNameTag === '1' ? (
        <div className="text-color-green font-size-16 font-widget-600">全部实名</div>
      ) : realNameTag === '2' ? (
        <div className="text-color-origin font-size-12 font-widget-600">
          {realCount?.description ? `${realCount.description}未实名` : '部分实名'}
        </div>
      ) : (
        <div className="text-color-red  font-size-16 font-widget-600">均未实名</div>
      )}
      <img className="pos-a r-10 b-10 icon-30" src="/pic/icon_realname.png" alt="" />
    </div>
  );

  let inspectBlock = (
    <div className="d-flex-c w-155 h-90 p-10 pos-r bg-white border-radius-10 mt-10" onClick={handleInspect}>
      <div className="text-color-9 flex-1">智能检测</div>

      <div className="font-size-10 text-color-9">当设备不能</div>
      <div className="font-size-10 text-color-9">联网时检测原因</div>

      <img className="pos-a r-10 b-10 icon-30" src="/pic/icon_inspect.png" alt="" />
    </div>
  );
  // 是否显示不限量
  var isUnlimit = flowKTOG(currentEm?.totalFlow ?? 0) >= main.showUnlimited;
  // 运营商
  let isCurrentArr = (currentEm?.cardDetails ?? []).filter((item: EmCard) => item.isCurrent === 'Y');
  let switchStatusArr = (currentEm?.cardDetails ?? []).filter((item: EmCard) => item.switchStatus === 'Y');
  // 如果isCurrent === ‘Y’ 就显示 如果都没有符合条件的就判断switchStatus === ‘Y’ 就显示
  let operator = currentEm?.cardDetails
    ? isCurrentArr?.length > 0
      ? isCurrentArr[0]?.operatorType
      : switchStatusArr?.length > 0
      ? switchStatusArr[0]?.operatorType
      : ''
    : '';
  return (
    <Page className="d-flex-c bg-gray home-page" contentClassName="d-flex-c flex-1 h-1 pos-r">
      <img className="bw-100 pos-a" src="/pic/pic_page_bg.png" alt="" />
      <div className="pos-a l-0 r-0 t-0 b-0 pl-20 pr-20 pt-20  overflow-y">
        <div className="d-flex-r ai-center">
          <div className={`w-8 h-8 border-radius-harf mr-10 ${currentEm?.status === 'Y' ? 'bg-green' : 'bg-red'}`} />
          <span className="font-size-12">设备号:{currentEm?.emNumber}</span>
          <img
            className="icon-12 ml-10"
            src="/pic/icon_action_copy.png"
            alt=""
            onClick={() =>
              copyToClipboard(currentEm?.emNumber!, () => {
                showToast('复制成功');
              })
            }
          />
          <div className="flex-1"></div>
          <div className="d-flex-r ai-center" onClick={() => navigate('/em-list')}>
            <img className="icon-12 mr-10" src="/pic/icon_swap.png" alt="" />
            <div className="text-color-p">切换设备</div>
          </div>
        </div>
        <div className="mt-20 d-flex-r">
          <div className="flex-1 h-100">
            <div className="d-flex-r ai-start font-widget-600">
              <div className="font-size-40 ">
                {isUnlimit ? '不限量' : surplusFlow <= 0 ? 0 : formatNumber(surplusFlow)}
              </div>
              {!isUnlimit && <div className="font-size-16 mt-5">G</div>}
            </div>
            <div className="font-size-12">本期剩余流量</div>
          </div>
          <div className="flex-1 h-100 d-flex-c pb-30 pl-30 ai-center jc-center">
            <Button
              className="btn-shadow"
              type="primary"
              size="lg"
              shape="circle"
              onClick={() => navigate('/select-pack')}
            >
              <div className="w-100">订购套餐</div>
            </Button>
          </div>
        </div>
        <div className="d-flex-r">
          <div className="flex-1 d-flex-c pr-20">
            <div className="bg-white border-radius-10 d-flex-c flex-1 p-10 pos-r">
              <div className="text-color-9 flex-1">当前套餐</div>
              <div className="font-widget-600 mt-5 font-size-16">{currentEm?.packDetailName ?? '--'}</div>
              <div className="font-size-12 text-color-9 mt-5">
                有效期：{currentEm?.endEffectTime ? dayjs(currentEm?.endEffectTime).format('YYYY-MM-DD') : '--'}
              </div>
              <img className="pos-a r-10 b-30 icon-40" src="/pic/icon_curr_pack.png" alt="" />
            </div>
            <div className="pt-10"></div>
            <div className="bg-white border-radius-10 flex-1 p-10 pos-r">
              <div className="text-color-9 flex-1">已用流量</div>
              <div className="d-flex-r ai-end mt-5">
                <span className="font-widget-600 font-size-16 ">{formatNumber(flowKTOG(currentEm?.usedFlow))}</span>
                <span className="font-size-12 font-widget-600 ml-5 mt-2">GB</span>
              </div>
              <img className="r-10 b-10 pos-a icon-40" src="/pic/icon_flow.png" alt="" />
            </div>
          </div>
          <div
            className="bg-white border-radius-10 w-155 p-10 pt-20 pb-24 d-flex-c"
            onClick={() => navigate('/recharge')}
          >
            <div className="text-color-9 flex-1">当前余额</div>
            <div className="d-flex-r">
              <div className="flex-1">
                <div className="flex-1 d-flex-r ai-end">
                  <span className="font-size-22 font-widget-600">
                    {formatNumber(currentEm?.cardDetailWalletVo?.availableAmount)}
                  </span>
                  <span className="font-size-12 ml-5 mb-3">元</span>
                </div>
                <div className="mt-10 text-color-p text-underline">去充值</div>
              </div>
              <img className="icon-40" src="/pic/icon_balance.png" alt="" />
            </div>
          </div>
        </div>
        {!!reBuyEntery && (
          <div className="bg-white mt-10">
            <img
              className="bw-100"
              src={reBuyEntery!.configValue}
              alt=""
              onClick={() =>
                window.open(`${reBuyEntery!.remark?.startsWith('http') ? '' : 'http://'}${reBuyEntery!.remark}`)
              }
            />
          </div>
        )}
        <div className="d-flex-r flex-wrap">
          {realNameTag === '1' ? inspectBlock : realBlock}
          <div
            className="ml-20 d-flex-c w-155 h-90 p-10 pos-r bg-white border-radius-10 mt-10"
            onClick={() => navigate('/switch-network')}
          >
            <div className="text-color-9 flex-1">切换网络</div>
            <div className="font-size-10 text-color-9 mb-5">当前网络运营商</div>
            <div className="font-size-16 font-widget-600">
              {operator !== '' ? OperatorType.getTypeStr(operator) : '---'}
            </div>

            <img className="pos-a r-10 b-10 icon-30" src="/pic/icon_web.png" alt="" />
          </div>
          <div
            className="d-flex-c w-155 h-90 p-10 pos-r bg-white border-radius-10 mt-10"
            onClick={() => navigate('/pack-order-list')}
          >
            <div className="text-color-9 flex-1">套餐管理</div>
            <div className="font-size-16 font-widget-600 mb-5">
              {orderCount}
              <span className="font-size-10 ml-10">个</span>
            </div>
            <div className="font-size-10 text-color-9">可用套餐</div>

            <img className="pos-a r-10 b-10 icon-30" src="/pic/icon_pack.png" alt="" />
          </div>
          <div
            className="ml-20 d-flex-c w-155 h-90 p-10 pos-r bg-white border-radius-10 mt-10"
            onClick={() => navigate('/income-list')}
          >
            <div className="text-color-9 flex-1">充消记录</div>
            <div className="font-size-16 font-widget-600 mb-5">
              {incomeCount}
              <span className="font-size-10 ml-10">条</span>
            </div>
            <div className="font-size-10 text-color-9">本月记录</div>

            <img className="pos-a r-10 b-10 icon-30" src="/pic/icon_income.png" alt="" />
          </div>
          {realNameTag === '1' ? realBlock : inspectBlock}
          <div
            className="ml-20 d-flex-c w-155 h-90 p-10 pos-r bg-white border-radius-10 mt-10"
            onClick={() => navigate('/setting')}
          >
            <div className="text-color-9 flex-1">设置</div>

            <div className="font-size-10 text-color-9">查看相关信息、</div>
            <div className="font-size-10 text-color-9">退出登录</div>

            <img className="pos-a r-10 b-10 icon-30" src="/pic/icon_setting.png" alt="" />
          </div>
          <div
            className="d-flex-c w-155 h-90 p-10 pos-r bg-white border-radius-10 mt-10"
            onClick={() => navigate('/coupon-list')}
          >
            <div className="text-color-9 flex-1">卡券中心</div>
            <div className="font-size-16 font-widget-600 mb-5">
              {couponCount}
              <span className="font-size-10 ml-10">条</span>
            </div>
            <div className="font-size-10 text-color-9">可用优惠券</div>

            <img className="pos-a r-10 b-10 icon-30" src="/pic/icon_coupon.png" alt="" />
          </div>
        </div>
        <div className="text-center text-color-9 p-20 font-size-12">
          <span onClick={handleCallPhone}>服务热线</span>
          <span className="ml-10 mr-10">|</span>
          <span onClick={handleToComplaint}>投诉建议</span>
        </div>
      </div>
      {hasChargeFirstActive && <FirstActiveFloat />}
    </Page>
  );
}
