import React from 'react';
import ReactDOM from 'react-dom/client';

export function rpx(t: number): number {
  return (t / 375) * window.innerWidth;
}

// 延迟执行
export function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function BottomBox({ content, onOk }: { content: React.ReactNode; onOk: (v: any) => void }) {
  return (
    <div className="bottom-box">
      <div className="mask" onClick={() => onOk(0)}></div>
      <div className="flex-1"></div>
      <div className="content bw-100">{content}</div>
    </div>
  );
}

export function showBottom(buildContent: (onOk: (value: unknown) => void) => React.ReactNode): Promise<any> {
  return new Promise((resolve) => {
    let div = document.createElement('div');
    document.body.appendChild(div);
    let root = ReactDOM.createRoot(div);

    document.body.style.overflow = 'hidden';
    const onOk = (v: any) => {
      if (root) {
        root.unmount();
        if (document.body.contains(div)) {
          document.body.removeChild(div);
        }
      }
      document.body.style.overflow = '';
      resolve(v);
    };
    root.render(<BottomBox content={buildContent(onOk)} onOk={onOk} />);
  });
}

interface ConfirmBoxProps {
  title?: string;
  content: React.ReactNode;
  hideConfirmBtn?: boolean;
  hideCancelBtn?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

function ConfirmBox({
  title,
  content,
  hideConfirmBtn,
  hideCancelBtn,
  confirmBtnText,
  cancelBtnText,
  onConfirm,
  onCancel,
}: ConfirmBoxProps) {
  return (
    <div className="confirm-box">
      <div className="content">
        <div className="p-10 font-widget-600 bw-100 font-size-16 d-flex-r ai-center">
          <img className="icon-20 view-opacity-0" src="/pic/icon_close_black.png" alt="" />
          <div className="flex-1 text-center">{title ?? '提示'}</div>
          <img className="icon-20" src="/pic/icon_close_black.png" alt="" onClick={onCancel} />
        </div>
        <div className="text">{content}</div>
        <div className="d-flex-r border-top bw-100">
          {!hideCancelBtn && (
            <div className="flex-1 p-10 text-center border-right" onClick={onCancel}>
              {cancelBtnText ?? '取消'}
            </div>
          )}
          {!hideConfirmBtn && (
            <div className="flex-1 p-10 text-center text-color-p" onClick={onConfirm}>
              {confirmBtnText ?? '确定'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export async function showConfirm({
  title,
  content,
  hideConfirmBtn,
  hideCancelBtn,
  confirmBtnText,
  cancelBtnText,
}: ConfirmBoxProps): Promise<any> {
  return new Promise((resolve) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const root = ReactDOM.createRoot(div);
    document.body.style.overflow = 'hidden';
    const onOk = (v: any) => {
      if (root) {
        root.unmount();
        if (document.body.contains(div)) {
          document.body.removeChild(div);
        }
        document.body.style.overflow = '';
        resolve(v);
      }
    };
    root.render(
      <ConfirmBox
        title={title}
        content={content}
        hideConfirmBtn={hideConfirmBtn}
        hideCancelBtn={hideCancelBtn}
        confirmBtnText={confirmBtnText}
        cancelBtnText={cancelBtnText}
        onConfirm={() => onOk(1)}
        onCancel={() => onOk(0)}
      />
    );
  });
}

interface ToastBoxProps {
  msg: string;
}

function ToastBox({ msg }: ToastBoxProps) {
  return (
    <div className="toast-box">
      <div className="toast">{msg}</div>
    </div>
  );
}
type ToastClose = () => void;
export function showToast(msg: string): ToastClose {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  root.render(<ToastBox msg={msg} />);
  let ref: { root?: ReactDOM.Root; div: HTMLDivElement } = { root, div };

  setTimeout(() => {
    if (ref.root) {
      ref.root.unmount();
      document.body.removeChild(ref.div);
      ref.root = undefined;
    }
  }, 2000);
  return () => {
    if (ref.root) {
      ref.root.unmount();
      document.body.removeChild(ref.div);
      ref.root = undefined;
    }
  };
}

export class LoadingMask {
  private static hideList: Array<() => void> = [];
  static hide = () => {
    for (let fn of LoadingMask.hideList) {
      fn();
      LoadingMask.hideList.shift();
    }
  };
  static show = (tips?: string) => {
    let fn = loadingMaskShow(tips ?? '正在加载...');
    LoadingMask.hideList.push(fn);
  };
}

function loadingMaskShow(tips: string) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  document.body.style.overflow = 'hidden';
  root.render(
    <div className="loading-mask">
      <div className="loading-mask-center">
        <img className="loading-pic rotating" src="/pic/icon_loading.png" alt="" />
        <div className="loading-tips">{tips}</div>
      </div>
    </div>
  );
  let ref: { root?: ReactDOM.Root; div: HTMLDivElement } = { root, div };
  return () => {
    document.body.style.overflow = '';
    if (ref.root) {
      ref.root.unmount();
      document.body.removeChild(ref.div);
      ref.root = undefined;
    }
  };
}

// 解析url参数
export function parseLocationParams(params?: string): { [x: string]: string } {
  if (!params) return {};
  let real = params.startsWith('?') ? params.split('?')[1] : params;
  let _tmpList = real.split('&') || [];
  let p: { [x: string]: string } = {};
  for (let _tmp of _tmpList) {
    let [_k, _v] = _tmp.split('=');
    p[_k] = _v;
  }
  return p;
}

export async function copyToClipboard(text: string, onSuccess?: () => void) {
  if (!text) {
    return showToast('内容为空,不可复制');
  }
  let div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  root.render(
    <div className={`copy-to-clipboard`}>
      <textarea id="copyToClipboard" defaultValue={text} className="text-area"></textarea>
    </div>
  );
  await delay(200);
  let ta: HTMLTextAreaElement | undefined = document.getElementById('copyToClipboard') as HTMLTextAreaElement;
  if (ta) {
    ta.select();
    let res = document.execCommand('copy');
    if (res) {
      if (onSuccess) {
        onSuccess();
      } else {
        showToast('复制成功');
      }
    }
  }
  await delay(500);
  root.unmount();
  document.body.removeChild(div);
}

export function flowKTOG(n?: number) {
  return (n || 0) / 1024 / 1024;
}

// 解析价格
export function modelParsePrice(data: string | number) {
  var tmp = parseFloat((data ?? '0').toString()) ?? 0;
  return tmp;
}

// 格式化数字类型的值
export function formatNumber(n: string | number | null | undefined) {
  let t = (n ?? 0).toString();
  if (parseFloat(t) !== parseInt(t)) {
    return parseFloat(t).toFixed(2);
  }
  return parseInt(t).toString();
}
